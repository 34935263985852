import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { AVAILABLE_STATUS, Sort, Filter, FilterKeys } from 'contexts/preferences/listing-params/types';
import { AreaListingsRouteMatchObject,
  generateRouteMatchObjectFromPath,
  CITY_LOCATION_TYPE,
  COUNTRY_LOCATION_TYPE,
  PROVINCE_LOCATION_TYPE,
} from '../route-matchers';
import AreaListingsPageView, { Pagination } from './area_listings_page_view';
import { AreaPageListingCardData } from './area_page_listing_card_data';
import getTargetedUrl, { TargetedUrlHelper } from './targeted-url-helper';
import { navigateToMapView } from 'utils/redirect-to-map-view';
import { useUserContext, useModalContext, usePreferencesContext, useThemeContext, CREATE_SAVED_SEARCH_MODAL, useFeaturesContext } from 'contexts';
import { useRouter } from 'next/router';
import deepmerge from 'deepmerge';
import { diff } from 'deep-object-diff';
import { trackEvent } from 'utils/google-tag-manager';
import { CountryCodeList } from 'types/countries';
import { isUsStateCode } from 'utils/province_or_state';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { handleSearchResult } from 'components/advanced-search';
import { getPositionFromDescription } from 'utils/google-maps/getPositionFromDescription';
import { IsUserInQuebec, handleQuebecPopup } from 'utils/modals';
import useDebounce from 'hooks/use-debounce';
import { hasFilterDeprecationBeenAck, setFilterDeprecationAck } from 'utils/ack_filter_deprecation';
import { hasDeprecatedHomeType } from 'utils/listing-query-helper';

import type { AreaPageListing } from '@zoocasa/go-search';
import type { ListingCardData } from 'components/listing-card';
import type { AreaListingsPageViewModel } from './area_listings_page_view_model';
import type { PartialDeep } from 'type-fest';
import type SearchPrediction from 'data/search-predictions';
import type { ThemeNames } from 'types/themes';
import { isClientSide } from 'utils/host-config';
import Address, { US_ADDRESS_ID } from 'data/addresses';

//#region Types
export interface AreaListingsPageControllerProps {
    viewModel: AreaListingsPageViewModel;
}

interface ScrollPosition {
    position: number;
    url: string;
}
//#endregion

//#region Constants

export const ignoredFilters: readonly FilterKeys[] = ['slug', 'latitude', 'longitude', 'zoom', 'areaName', 'boundary'];

const DEFAULT_BROADER_AREA = 'Canada';
//#endregion

const showFilterDeprecationWarningInitialState = (filter: PartialDeep<Filter>) => () => isClientSide() && !hasFilterDeprecationBeenAck() && hasDeprecatedHomeType(filter as Filter);

const areaPageListingToListingCardData = (listing: AreaPageListing) => (new AreaPageListingCardData(listing));

const getCountryNameFromAddress = (address: Address) => {
  const addressId = address?.id?.toString();
  switch (addressId) {
  case US_ADDRESS_ID:
    return 'USA';
  default:
    return DEFAULT_BROADER_AREA;
  }
};

const getCountryNameFromCode = (address: Address) => {
  const countryCode = address?.country;
  switch (countryCode.toUpperCase()) {
  case CountryCodeList.UNITED_STATES:
    return 'USA';
  case CountryCodeList.CANADA:
    return 'Canada';
  default:
    return DEFAULT_BROADER_AREA;
  }
};

const AreaListingsPageController = (props: AreaListingsPageControllerProps) => {

  const { viewModel: {
    pagination: {
      page, count, total, size,
    },
    breadcrumbs,
    addresses,
    heading,
    genericNamedContent,
    areaBlurb,
    areaBlurbHeading,
    isCrawler,
    isCrawlerLighthouse,
    footerData,
    listings,
    sort,
    fallbackAddress,
  }} = props;
  const { listingParams, addToRecentSearches } = usePreferencesContext();
  const { openModal } = useModalContext();
  const { user, isAuthenticated, siteLocation, userLocation } = useUserContext();
  const { themeName } = useThemeContext();
  const { features } = useFeaturesContext();
  const { push, asPath } = useRouter();
  const [listingsData] = useState<ListingCardData[]>(() => {
    const initialState = listings.map((listing: AreaPageListing) => areaPageListingToListingCardData(listing));
    return initialState;
  });
  const [hasNoListingsInArea] = useState(fallbackAddress !== null);
  const routeMatchObject: AreaListingsRouteMatchObject = generateRouteMatchObjectFromPath(asPath, false);
  const paginationData: Pagination = { page: page + 1, size, count, total };
  const { locationType, city, province, provinceCode, filter } = routeMatchObject;
  const { status } = filter;
  const routeMatchLocationType = locationType || city && CITY_LOCATION_TYPE || province && PROVINCE_LOCATION_TYPE || COUNTRY_LOCATION_TYPE;
  const showGuides = status === AVAILABLE_STATUS || count > 0;
  const showDisclaimer = !!(provinceCode && isUsStateCode(provinceCode));

  const [showFilterDeprecationWarning, setShowFilterDeprecationWarning] = useState(showFilterDeprecationWarningInitialState(deepmerge(defaultListingParams.filter, filter as Filter)));

  const onFiltersChanged = (changedFilters: PartialDeep<Filter>) => {
    // Clean up any unwanted properties that trigger a filter change
    const sanitizedFilter = Object.keys(changedFilters)
      .filter(key => !ignoredFilters.includes(key as FilterKeys))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: changedFilters[key as FilterKeys],
        };
      }, {} as PartialDeep<Filter>);

    const consolidatedFilter = deepmerge(listingParams.filter, sanitizedFilter);
    // We need to merge the consolidated filters with the default filter values since in a lot of places the code expects
    // all filter values to be set even if they are not relevant to the component accessing it.
    const listingParamsFilter = deepmerge(defaultListingParams.filter, consolidatedFilter as Filter);
    const cookieFiltersClone: Filter = cloneDeep(listingParams.filter);
    const listingParamsFilterClone: Filter = cloneDeep(listingParamsFilter);
    ignoredFilters.forEach(key => {
      delete cookieFiltersClone[key];
      delete listingParamsFilterClone[key];
    });

    const filterDiff = diff(cookieFiltersClone, listingParamsFilterClone);
    if (Object.keys(filterDiff).length !== 0) {
      // This will trigger a rerender as this component is an observer for the Mobx storage and it is necessary because
      // when we navigate to the map page, we reset the smart url and go back to the /search route. That way the map component
      // depends on the listingParams to figure out the current filters
      listingParams.setFilter(listingParamsFilter);
      if (user?.id && listingParams) {
        captureLastSearch({ userId: user.id, lastSearch: listingParams, sourceTheme: themeName });
      }

      const targetedUrlConfig: TargetedUrlHelper = { 
        url: `${window.location.origin}${window.location.pathname}`,
        filters: consolidatedFilter as Filter,
        sort,
        pageNumber: page,
        fallbackSlug: '',
        filtersChanged: true,
        useLegacySearchFilter: features.useLegacySearchFilter,
      };

      const targetedPath = getTargetedUrl(targetedUrlConfig);
      push(targetedPath);
    }
  };

  const onSortChanged = (newSort: Sort) => {
    if (sort != newSort) {
      listingParams.setSort(newSort);
      if (user?.id && listingParams) {
        captureLastSearch({ userId: user.id, lastSearch: listingParams, sourceTheme: themeName });
      }

      const filterClone: Filter = cloneDeep(filter as Filter);
      const targetedUrlConfig: TargetedUrlHelper = { 
        url: `${window.location.origin}${window.location.pathname}`, 
        filters: filterClone, 
        sort: newSort, 
        pageNumber: page, 
        fallbackSlug: fallbackAddress?.slug, 
        filtersChanged: true, 
        useLegacySearchFilter: features.useLegacySearchFilter,
      };
      const targetedPath = getTargetedUrl(targetedUrlConfig);
      push(targetedPath);
    }
  };

  const setPageNumber = (pageNumber: number) => {
    if (pageNumber != paginationData.page) {
      const filterClone: Filter = cloneDeep(filter as Filter);
      const targetedUrlConfig: TargetedUrlHelper = { 
        url: `${window.location.origin}${window.location.pathname}`, 
        filters: filterClone, 
        sort, 
        pageNumber, 
        fallbackSlug: fallbackAddress?.slug, 
        filtersChanged: false, 
        useLegacySearchFilter: features.useLegacySearchFilter,
      };
      const targetedPath = getTargetedUrl(targetedUrlConfig);
      push(targetedPath);
    }
  };

  const openSavedSearchFiltersModal = () => openModal('saved-search-filters', { setAreaFilters: onFiltersChanged });
  const handleMobileFilterButtonClick = openSavedSearchFiltersModal;
  const handleMoreButtonClick = () => {
    trackEvent('UiAreaQfMoreButClk');
    openSavedSearchFiltersModal();
  };

  const handleSaveSearchButtonClick = () => {
    if (isAuthenticated) {
      const saveWithFetchedPosition = async () => { // Sets position if user lands on area page with URL
        if (listingParams.filter.slug) {
          const position = await getPositionFromDescription(listingParams.filter.slug, true);
          const latitude = position?.latitude;
          const longitude = position?.longitude;
          if (latitude && longitude) {
            listingParams.setFilter({
              ...listingParams.filter,
              latitude,
              longitude,
            });
            openModal(CREATE_SAVED_SEARCH_MODAL, { query: { ...listingParams, filter: { ...listingParams.filter, boundary: null, latitude, longitude }}});
          }
        }
      };
      saveWithFetchedPosition();
    } else {
      openModal('login-registration');
    }
  };

  const onSearchResultClick = (searchPrediction: SearchPrediction) => {
    handleSearchResult({
      searchPrediction,
      listingParams,
      user,
      addToRecentSearches,
      themeName: themeName as ThemeNames,
    });
  };

  const handleMapIconClick = () => {
    trackEvent('UiAreaQfMapButClk');
    navigateToMapView(listingParams, addresses, push, siteLocation===CountryCodeList.UNITED_STATES);
  };

  const getBroaderAreaForListings = (address: Address) => {
    if (fallbackAddress?.addressType === 'country') {
      return getCountryNameFromAddress(address);
    }
    if (fallbackAddress?.country) {
      return getCountryNameFromCode(address);
    }
    return DEFAULT_BROADER_AREA;
  };

  const generateNoListingsInAreaMessage = () => {
    const broaderArea = getBroaderAreaForListings(fallbackAddress);
    const formattedBroaderArea = capitalizeWords(fallbackAddress?.label?.toLowerCase() || broaderArea);
    if (routeMatchObject[routeMatchLocationType]) {
      const area = capitalizeWords(deDasherize(routeMatchObject[routeMatchLocationType] as string));
      return `We couldn't find any real estate listings in ${area}. Here are some listings in ${formattedBroaderArea}`;
    }
    return `We couldn't find listings matching the criteria but here are some in ${formattedBroaderArea}...`;
  };

  useEffect(() => {
    trackEvent('UiAreapView');

    const scrollPosition = sessionStorage.getItem('scroll-position');
    if (scrollPosition) {
      const scrollPositionObject = JSON.parse(scrollPosition) as ScrollPosition;
      // We only want to scroll if the current area page matches the area page that we went to an address page from, otherwise it is a different area page
      if (window.location.pathname === scrollPositionObject.url) {
        window.scrollTo(0, scrollPositionObject.position);
      }
    }
  }, []);

  const debouncedHandleQuebecPopup = useDebounce(() => {
    const isQuebec = !IsUserInQuebec(userLocation) && routeMatchObject.provinceCode === 'qc';
    handleQuebecPopup({ themeName, isQuebec, openModal, source: 'area' });
  }, 300);
  
  useEffect(() => {
    // Handles quebec popup when user not in Quebec but searches for Quebec area pages
    debouncedHandleQuebecPopup();
  }, [userLocation, openModal, routeMatchObject, themeName, debouncedHandleQuebecPopup]);

  const handleDeprecationWarningButtonClicked = () => {
    setFilterDeprecationAck(true);
    setShowFilterDeprecationWarning(false);
  };
  
  return (<AreaListingsPageView
    listings={listingsData}
    breadcrumbs={breadcrumbs}
    footerData={footerData}
    routeMatchObject={routeMatchObject}
    pagination={paginationData}
    sort={sort}
    heading={heading}
    areaBlurb={areaBlurb}
    areaBlurbHeading={areaBlurbHeading}
    noListingsInAreaMessage={generateNoListingsInAreaMessage()}
    hasNoListingsInArea={hasNoListingsInArea}
    isCrawler={isCrawler}
    isCrawlerLighthouse={isCrawlerLighthouse}
    showGuides={showGuides}
    showDisclaimer={showDisclaimer}
    genericNamedContent={genericNamedContent}
    setPageNumber={setPageNumber}
    onSortChanged={onSortChanged}
    onMobileFilterButtonClick={handleMobileFilterButtonClick}
    onValueChange={onFiltersChanged}
    onSaveSearchButtonClick={handleSaveSearchButtonClick}
    onLocationSearchClick={onSearchResultClick}
    onMapIconClick={handleMapIconClick}
    onMoreButtonClick={handleMoreButtonClick}
    listingParams={listingParams}
    showFilterDeprecationWarning={showFilterDeprecationWarning}
    onDeprecationWarningButtonClick={handleDeprecationWarningButtonClicked}
  />);
};

export default AreaListingsPageController;