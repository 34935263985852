import React, { useEffect, useState } from 'react';
import { getCities, getProvinces } from 'utils/get-cities-or-provinces';
import { CountryCode, CountryCodeList } from 'types/countries';
import styles from './style.module.scss';
import { ProvinceOrStateCode, provinceOrStateNameFromCode } from 'utils/province_or_state';

interface SeoLinksProps {
  country: CountryCode;
  province?: string;
}

const ProvinceCitySeoLinks: React.FC<SeoLinksProps> = ({ country, province }) => {
  const [links, setLinks] = useState<{ name: string; link: string; label: number }[]>([]);
  const isProvincePresent = !!province as boolean;
  const stateOrProvinceStr = country === CountryCodeList.UNITED_STATES ? 'States' : 'Provinces';
  useEffect(() => {
    const fetchProvinces = async () => {
      const provinceData = await getProvinces(country);
      provinceData.sort((a, b) => a.name.localeCompare(b.name));
      setLinks(provinceData);
    };
    const fetchCities = async () => {
      const citiesData = await getCities(country, province);
      citiesData.sort((a, b) => a.name.localeCompare(b.name));
      setLinks(citiesData);
    };

    if (isProvincePresent) {
      fetchCities();
    } else {
      fetchProvinces();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, province]);

  return (
    <div className={styles.component}>
      {isProvincePresent ?
        <h2 className={styles.title}>
          All Cities in {provinceOrStateNameFromCode(province as ProvinceOrStateCode)}
        </h2>
        :
        <h2 className={styles.title}>
          All {stateOrProvinceStr}
        </h2>
      }
      <div className={styles.list}>
        {links.map((link, index) => (
          <div key={index} className={styles.item}>
            <a href={link.link}>{link.name}</a> <span className={styles.label}>({link.label})</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProvinceCitySeoLinks;
